<template>
  <div>
    <smart-table ref="smartTable"
                 :title="translations.employeeRequest"
                 :headers="headers()"
                 :default-sort="defaultSort()"
                 :search-function="searchFunction">

      <!-- page-header-left: go to vehicle -->
      <template v-slot:page-header-left>
        <h5 class="main--text" style="cursor:pointer" @click="$router.push('/back-office-vehicle')">
          <v-icon :size="10">mdi-arrow-left</v-icon>
          {{ translations.goTo }} {{ translations.vehicleRequest }}
        </h5>
      </template>

      <!-- page-header-right: print report button -->
      <template v-slot:page-header-right-1>
        <v-btn icon dark color="main" @click="reportActivityRequests">
          <v-icon :size="28">mdi-printer</v-icon>
        </v-btn>
      </template>

      <!-- page-header-right: new request dialog -->
      <template v-slot:page-header-right-2>
        <activity-request-new @updated="$refs.smartTable.getTableRows()" />
      </template>

      <!-- smart table: results -->
      <template v-slot:results="{ item: item }">
        <!-- employee name -->
        <td>
          {{ item.employee.name }}
        </td>

        <!-- type -->
        <td>
          {{ item.employeeStatus.description }}
        </td>

        <!-- date from -->
        <td>
          {{ momentDate(item.dateFromStr) }}
        </td>

        <!-- date to -->
        <td>
          {{ momentDate(item.dateToStr) }}
        </td>

        <!-- day part -->
        <td>
          {{ $store.getters.activityRequestDayPartsMap[item.dayPart] }}
        </td>

        <!-- status -->
        <td>
          <v-tooltip top color="black" :disabled="!(item.status === 'REJECTED' && item.note && item.note.length > 0)">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-on="on" v-bind="attrs" small label class="status-chip mr-2"
                      :color="$store.getters.activityRequestStatusesColors[item.status].background">
                <div :style="[{ color: $store.getters.activityRequestStatusesColors[item.status].color }]">
                  {{ translations[item.status.toLowerCase()] }}
                  <span v-if="item.status === 'REJECTED' && item.note && item.note.length > 0">*</span>
                </div>
              </v-chip>
            </template>
            {{ item.note }}
          </v-tooltip>
        </td>

        <!-- actions -->
        <td>
          <activity-request-edit :item="item" @updated="$refs.smartTable.getTableRows()" />
        </td>
      </template>
    </smart-table>
  </div>
</template>


<script>
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'
import ActivityRequestService from '@/service/activityRequest/ActivityRequestService'
import reportsApi from '@/utils/api/reportsApi'

import SmartTable from '@/components/SmartTable.vue'
import ActivityRequestEdit from '@/views/ActivityRequest/ActivityRequestEdit.vue'
import ActivityRequestNew from '@/views/ActivityRequest/ActivityRequestNew.vue'

export default {
  name: 'ActivityRequest',

  components: {
    SmartTable,
    ActivityRequestEdit,
    ActivityRequestNew
  },

  data() {
    return {
      translations: translations
    }
  },

  mixins: [MomentMixin],

  methods: {
    headers() {
      const headers = []

      headers.push({
        type: 'TEXT',
        name: 'employeeName',
        label: translations.employee,
        sort: 'employee.name'
      })

      headers.push({
        type: 'SELECT',
        name: 'employeeStatuses',
        label: translations.requestType,
        selectItems: this.$store.getters.employeeStatusesSelect,
        value: [1, 3],
        sort: 'employeeStatus.description'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'dateFromStr',
        label: translations.date + ' (' + translations.from + ')',
        sort: 'dateFrom'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'dateToStr',
        label: translations.date + ' (' + translations.to + ')',
        sort: 'dateTo'
      })

      headers.push({
        type: 'SELECT',
        name: 'dayParts',
        label: translations.dayPart,
        selectItems: this.$store.getters.activityRequestDayParts,
        sort: 'dayPart'
      })

      headers.push({
        type: 'SELECT',
        name: 'statuses',
        label: translations.status,
        selectItems: this.$store.getters.activityRequestStatuses,
        value: ['PENDING'],
        sort: 'status'
      })

      // actions
      headers.push({})

      return headers
    },

    defaultSort() {
      return [
        { name: 'dateFrom', value: 'DESC' },
        { name: 'employee.name', value: 'ASC' }
      ]
    },

    searchFunction(params) {
      return ActivityRequestService.search(params)
    },

    reportActivityRequests() {
      reportsApi.activityRequests(this.$refs.smartTable.getParams())
    }
  }
}
</script>
