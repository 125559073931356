<template>
  <main class="smart-page-overflow ma-4">


    <!-- page header -->
    <div class="smart-page-header-3 mx-2">

      <!-- page header: left -->
      <div class="smart-page-header-left">
        <slot name="page-header-left"></slot>
      </div>

      <!-- page header: center -->
      <div class="all-center">
        <h3 class="main--text">{{ title }}</h3>
      </div>

      <!-- page header: right -->
      <div class="all-center justify-space-around">
        <div class="all-center" style="width: 20%">
          <slot name="page-header-right-1"></slot>
        </div>

        <div class="all-center" style="width: 60%">
          <slot name="page-header-right-2"></slot>
        </div>
      </div>
    </div>


    <!-- main content -->
    <v-card class="ma-1">
      <!-- workaround to import the v-data-table css -->
      <v-data-table v-if="0===1" />

      <div class="smart-table v-data-table v-data-table--fixed-header v-data-table--has-bottom theme--light">
        <div class="v-data-table__wrapper">
          <table>
            <caption></caption>
            <thead class="smart-table-header v-data-table-header">

            <!-- headers -->
            <tr>
              <th scope="row" v-for="header in headers" :key="header.name">
                <div style="display: flex; align-items: center;">
                  <!-- sort -->
                  <div v-if="header.sort" @click="updateSort(header)" class="smart-table-header-sort">
                    <v-icon small>{{ checkSort(header) }}</v-icon>
                    {{ getSortPosition(header) }}
                  </div>

                  <!-- LABEL (no filter) -->
                  <template v-if="header.type === 'LABEL'">
                    {{ header.label }}
                  </template>

                  <!-- TEXT -->
                  <v-text-field v-if="header.type === 'TEXT'"
                                v-model="params[header.name]"
                                :label="header.label"
                                :hint="header.hint"
                                persistent-hint
                                @keydown.enter.stop="getTableRows"
                                @blur.stop="getTableRows">
                  </v-text-field>

                  <!-- SELECT -->
                  <smart-select v-if="header.type === 'SELECT'"
                                v-model="params[header.name]"
                                :label="header.label"
                                :hint="header.hint"
                                :items="header.selectItems"
                                :min-labels="header.selectMinLabels"
                                @change="getTableRows">
                  </smart-select>

                  <!-- DATE-PICKER -->
                  <smart-date-picker v-if="header.type === 'DATE-PICKER'"
                                     v-model="params[header.name]"
                                     :label="header.label"
                                     :hint="header.hint"
                                     @change="getTableRows">
                  </smart-date-picker>

                  <!-- help -->
                  <v-tooltip v-if="header.help" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" small class="smart-table-header-help">mdi-information-variant-circle-outline</v-icon>
                    </template>
                    {{ header.help }}
                  </v-tooltip>
                </div>
              </th>
            </tr>
            </thead>

            <!-- results: no data -->
            <tbody v-if="results.length === 0 && !overlay">
            <tr class="no-results">
              <td :colspan="headers.length">
                {{ translations.noResultsFound }}
              </td>
            </tr>
            </tbody>

            <!-- results: with data -->
            <tbody v-else>
            <tr v-for="item in results" :key="item.name" @click="goToDetail(item)">
              <slot name="results" :item="item"></slot>
            </tr>
            </tbody>
          </table>
        </div>

        <!-- footer -->
        <div class="smart-table-footer v-data-footer">
          <!-- footer: reload -->
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" color="main white--text" x-small fab @click="getTableRows">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              {{ translations.reload }}
            </v-tooltip>
          </div>

          <!-- footer: pagination -->
          <v-pagination v-if="paginated"
                        v-model="pagination.page"
                        :length="pagination.totalPages"
                        :disabled="pagination.totalPages === 1"
                        total-visible="5" circle />

          <!-- footer: page elements -->
          <div class="smart-table-footer-pageable">
            <div>
              {{ pagination.firstEl }} - {{ pagination.lastEl }} {{ translations.paginationOf }} {{ pagination.totalElements }}
            </div>

            <div v-if="paginated">
              {{ translations.paginationPerPage }}
              &nbsp;
              <select v-model="pagination.size">
                <option v-for="item in pagination.availableSizes" :key="item">{{ item }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </v-card>


    <!-- overlay -->
    <v-overlay z-index="10000" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </main>
</template>


<script>
import { translations } from '@/utils/common'

import SmartDatePicker from '@/components/SmartDatePicker'
import SmartSelect from '@/components/SmartSelect'

export default {
  name: 'SmartTable',

  components: {
    SmartDatePicker,
    SmartSelect
  },

  props: {
    title: {
      required: true,
      type: String
    },

    headers: {
      required: true,
      type: Array
    },

    searchFunction: {
      required: true,
      type: Function
    },

    paginated: {
      default: true,
      type: Boolean
    },

    defaultSort: {
      default: null,
      type: Array
    },

    detailFunction: {
      default: null,
      type: Function
    }
  },

  data() {
    return {
      translations: translations,

      params: {},
      pagination: {
        page: 1,
        totalElements: 0,
        totalPages: 1,
        size: 25,
        availableSizes: [25, 50, 75, 100],
        sorts: new Map()
      },
      results: [],

      overlay: false
    }
  },

  mounted() {
    // elaborate headers
    this.headers.forEach((header) => {
      // fill the already active params
      if (header.value) {
        this.params[header.name] = header.value
      }
    })

    // elaborate sort
    if (this.defaultSort) {
      this.defaultSort.forEach((s) => {
        this.pagination.sorts.set(s.name, s.value)
      })
    }

    // search data
    this.getTableRows()
  },

  methods: {
    checkSort(header) {
      const sort = this.pagination.sorts.get(header.sort)
      if (sort === undefined) {
        return 'mdi-sort-reverse-variant'
      }
      return sort === 'ASC' ? 'mdi-sort-descending' : 'mdi-sort-ascending'
    },

    getSortPosition(header) {
      let index = 0
      for (const [key] of this.pagination.sorts.entries()) {
        if (key === header.sort) {
          return index + 1
        }
        index += 1
      }
      return null
    },

    updateSort(header) {
      const sort = this.pagination.sorts.get(header.sort)
      if (sort === undefined) {
        this.pagination.sorts.set(header.sort, 'ASC')
      } else if (sort === 'ASC') {
        this.pagination.sorts.set(header.sort, 'DESC')
      } else {
        this.pagination.sorts.delete(header.sort)
      }
      this.getTableRows()
    },

    getSort() {
      const sort = []
      this.pagination.sorts.forEach((value, key) => {
        sort.push(`${key}:${value}`)
      })
      return sort
    },

    getParams() {
      return this.params
    },

    getTableRows() {
      this.overlay = true

      this.searchFunction({
          ...this.getParams(),
          page: this.pagination.page - 1,
          size: this.pagination.size,
          sort: this.getSort()
        })
        .then((response) => this.updateResults(response))
        .catch(() => this.updateResults())
        .finally(() => this.overlay = false)
    },

    updateResults(data) {
      if (data === null || data === undefined) {
        this.results = []
        this.pagination.totalElements = 0
        this.pagination.totalPages = 1
        this.pagination.firstEl = 0
        this.pagination.lastEl = 0
      } else if (this.paginated) {
        this.updateResultsPaginated(data)
      } else {
        this.updateResultsNotPaginated(data)
      }
    },

    updateResultsPaginated(data) {
      this.results = data.content
      this.pagination.totalElements = data.totalElements
      this.pagination.totalPages = data.totalPages
      if (data.totalElements === 0) {
        this.pagination.firstEl = 0
        this.pagination.lastEl = 0
      } else {
        this.pagination.firstEl = this.pagination.page * this.pagination.size - this.pagination.size + 1
        this.pagination.lastEl = this.pagination.page === data.totalPages ? this.pagination.totalElements : this.pagination.page * this.pagination.size
      }
    },

    updateResultsNotPaginated(data) {
      this.results = data
      this.pagination.totalElements = data.length
      this.pagination.totalPages = 1
      this.pagination.firstEl = 1
      this.pagination.lastEl = data.length
    },

    goToDetail(item) {
      if (this.detailFunction) {
        this.detailFunction(item)
      }
    }
  },

  computed: {
    // add cloned for the watch
    paginationCloned() {
      return JSON.parse(JSON.stringify(this.pagination))
    }
  },

  watch: {
    // on cloned computed prop
    paginationCloned: {
      handler(oldV, newV) {
        // set size when not valid
        if (!this.pagination.availableSizes.includes(parseInt(this.pagination.size, 10))) {
          // eslint-disable-next-line prefer-destructuring
          this.pagination.size = this.pagination.availableSizes[0]
        }

        // reset page when size is changed or current page exceed the totalPages
        if (this.pagination.page !== 1 && (oldV.size !== newV.size || this.pagination.page > this.pagination.totalPages)) {
          this.pagination.page = 1
        }

        // reload data
        this.getTableRows()
      },
      deep: true
    }
  }
}
</script>


<style>
.smart-page-overflow {
  overflow: hidden;
}

.smart-page-header-3 {
  height: 40px;

  margin-bottom: 20px;

  display: flex;
  justify-content: space-between;
}

.smart-page-header-3 > * {
  width: 33%;
}

.smart-page-header-left {
  display: grid;
  grid-template-columns: repeat(2, 48%);
  column-gap: 2%;
  align-items: end;
}

.smart-page-content {
  height: calc(100vh - 141px);

  border-radius: 4px !important;
}

.smart-table .v-data-table__wrapper {
  height: calc(100vh - 40px - 60px - 60px);
}

.smart-table-no-footer .v-data-table__wrapper {
  height: calc(100vh - 141px);

  border-radius: 4px !important;
}

.smart-table-custom {
  height: calc(100vh - 211px);

  border-radius: 4px !important;
  overflow-y: scroll;
}

.smart-table-header {
  width: 100%;
  height: 70px;
}

.smart-table-header-sort {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
}

.smart-table-header-sort .v-icon {
  padding-right: 2px;
}

.smart-table-header-help {
  padding-left: 4px;
  cursor: help;
}

.smart-table .no-results:hover {
  background: none !important;
}

.smart-table .no-results td {
  text-align: center;
  padding: 50px !important;
  cursor: default;
}

.smart-table-footer {
  width: 100%;
  height: 60px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
}

.smart-table-footer > * {
  width: 33%;
}

.smart-table-footer .v-pagination__navigation,
.smart-table-footer .v-pagination__item {
  box-shadow: none !important;
}

.smart-table-footer .v-pagination__item--active {
  background-color: #005792 !important;
  border-color: #005792 !important;
}

.smart-table-footer-pageable {
  display: flex;
  justify-content: space-around;
  cursor: default;
}

.smart-table-footer-pageable select {
  border-bottom: 1px solid gray;
  cursor: pointer;
}

.v-text-field .v-label {
  font-size: small;
}

.v-input input,
.v-select__selections {
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: small;
}

.v-tooltip__content {
  max-width: 200px;
}
</style>
