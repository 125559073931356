<template>
  <div>
    <!-- loading -->
    <v-progress-circular v-if="loading" :size="15" color="main" indeterminate />


    <div v-else class="actions">
      <!-- edit popup -->
      <v-menu v-model="actionMenu" :close-on-content-click="false" offset-x left>
        <!-- activator icon -->
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-on="on" v-bind="attrs" small class="mr-2">mdi-square-edit-outline</v-icon>
        </template>

        <v-card>
          <!-- title -->
          <v-card-title class="d-flex justify-center">
            <h4 class="main--text">{{ translations[item.status === 'PENDING' ? 'handleRequest' : 'changeRequest'] }}</h4>
          </v-card-title>

          <v-card-text>
            <!-- status buttons -->
            <div v-if="!showTextArea" class="d-flex justify-center">
              <v-btn v-if="item.status !== 'REJECTED'" @click="showTextArea = true" small color="error" class="mr-2" text depressed>{{ translations.deny }}</v-btn>
              <v-btn v-if="item.status !== 'APPROVED'" @click="changeActivityRequest('APPROVED')" small color="main" class="white--text" depressed>{{ translations.approve }}</v-btn>
            </div>

            <!-- reject's note input -->
            <div v-else>
              <div class="mb-2">
                {{ translations.optionalNoteForRequest }}:
              </div>
              <v-textarea v-model="item.note" filled flat dense hide-details height="100"></v-textarea>

              <div class="mt-4 d-flex justify-space-between">
                <v-btn @click="showTextArea = false" small text class="white--text" color="main">{{ translations.cancel }}</v-btn>
                <v-btn @click="changeActivityRequest('REJECTED')" small class="white--text" color="main">{{ translations.confirm }}</v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>


      <!-- delete -->
      <v-icon @click="deleteActivityRequest" small color="error lighten-1" class="cursor-pointer">mdi-delete-outline</v-icon>
    </div>
  </div>
</template>


<script>
import { translations } from '@/utils/common'
import ActivityRequestService from '@/service/activityRequest/ActivityRequestService'

export default {
  name: 'ActivityRequestEdit',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      translations: translations,

      actionMenu: false,
      showTextArea: false,
      loading: false
    }
  },

  methods: {
    async changeActivityRequest(action) {
      this.loading = true
      await ActivityRequestService.change({
          activityRequestId: this.item.id,
          employeeId: this.item.employee.id,
          status: action,
          note: this.item.note
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.$emit('updated')
        })
        .finally(() => {
          this.actionMenu = false
          this.showTextArea = false
          this.loading = false
        })
    },

    async deleteActivityRequest() {
      this.loading = true
      await ActivityRequestService.delete({
          activityRequestId: this.item.id,
          employeeId: this.item.employee.id
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.deletedSuccess })
          this.$emit('updated')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>


<style>
.actions {
  display: flex
}

.status-chip {
  min-width: 80px;
  justify-content: center;
}
</style>
